import { resolveGet, resolvePost, get, post } from '@/request'
import { SUFFIX } from '@/common'

// 查询所有设备
// export const queryLocusTreeList = async (params?: any) => {
//   return resolvePost({
//     suffix: SUFFIX.DCOP,
//     url: '/device/manage/treeList',
//     params,
//   })
// }

// 查询所有设备
export const queryAllDevice = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/device/locus/location/query/bycondit',
    params,
  })
}

// 查询轨迹
export const queryDevicePlayback = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/device/locus/playback/bycondit',
    params,
  })
}

// 设备轨迹添加
export const deviceLocationAdd = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/device/locus/location/add',
    params,
  })
}

// 查询轨迹分页
export const queryDevicePlaybackPage = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/device/locus/playback/page',
    params,
  })
}

// 设备轨迹添加
export const exportDeviceTrail = (params: any) => {
  return post({
    suffix: SUFFIX.DCOP,
    url: '/device/locus/playback/export',
    params,
    userConfig: {
      responseType: 'arraybuffer',
    },
  })
}
