// 设备消息
import { get, post, resolveGet, resolvePost } from '@/request'

import { SUFFIX } from '@/common'

//查询订单分页列表
export const queryTradeorderPage = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/manage/tradeorder/page',
    params,
  })
}
//查询订单分页列表
export const queryTradeorderUserVipPage = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/manage/tradeorder/uservip/page',
    params,
  })
}
// 查询用户分页列表
export const queryMemberPage = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/manage/member/page',
    params,
  })
}

// 查询用户分页列表
export const queryMemberDetail = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/manage/member/detail',
    params,
  })
}