export const CODE_ENUM = {
  SUCCESS: 0,
  // 权限
  AUTHORIZATION_NOT: 401,
}

// 服务
export const SUFFIX = {
  TIANSHU: '/api-tianshu',
  USER: '/api-user',
  DCOP: '/api-dcop',
}

// 地图类型
export const MAP_TYPE_ENUM = {
  STAR: 'STAR', // 卫星地图
  STAND: 'STAND', //标准地图
  TERRAIN: 'TERRAIN', //地形图
}

// 设备状态
export const DEVICE_STATUS_ENUM = {
  NOACTIVATE: 'noactivate', // 未激活
  INACTIVATE: 'inactivate', // 已激活
  OFFLINE: 'offline', // 离线
  ONLINE: 'online', // 在线
  NONE: 'none', // 多膜无状态
}
// 数据配置- 数据上传方式
export const DEVICE_DATA_UPLOAD_ENUM = {
  PROPERTIES: 'properties', // 变量模板
  UNRESOLVED: 'unresolved', // 透传
}

// 数据配置- 设备定位方式
export const DEVICE_LOCATION_TYPE_ENUM = {
  AUTO: 'auto', // 自动定位
  HAND: 'hand', // 手工定位
}

// 套餐类型
export const PACKAGE_TYPE_ENUM = {
  PACKAGE_COMPANY: 'PACKAGE_COMPANY', // 企业
  PACKAGE_PRIVATE: 'PACKAGE_PRIVATE', // 个人
}

// 套餐计费模式
export const PACKAGE_CHARGE_MODE_ENUM = {
  FREE: 'FREE', // 免费
  CHARGE: 'CHARGE', // 收费
  PACKYEAR: 'YEARLY_PACKAGE', //包年
  PACKMONTH: 'MONTHLY_PACKAGE', //包月
}

export const PRODUCT_TYPE = {
  SINGLE_GATEWAY: 'SINGLE_GATEWAY', //单模
  MULTI_GATEWAY: 'MULTI_GATEWAY', // 多模
  LORA: 'LORA', // LORA
  SAT_GROUND_STATION: 'SAT_GROUND_STATION', //地面站
  SENSOR: 'SENSOR', //传感器
}

// 数据类型
export const DATA_TYPE = {
  INT: 'int',
  DOUBLE: 'double',
  STRING: 'string',
  DATE: 'date',
  BOOL: 'bool',
  STRUCT: 'struct',
}
// 读写类型
export const ACCESS_MODE_TYPE = {
  RO: 'ro',
  WR: 'wr',
  RW: 'rw',
}
// 物模型类型
export const MODEL_TYPE = {
  ACTIONS: 'actions', // 方法
  EVENTS: 'events', // 事件
  PROPERTIES: 'properties', // 属性
}
// 事件类型
export const ACTIONS_CALL_TYPE = {
  SYN: 'syn', // 同步
  ASYN: 'asyn', // 异步
}

// 事件类型
export const ACTIONS_EVENT_TYPE = {
  INFO: 'info', // 信息
  ALARM: 'alarm', // 告警
}

// 告警等级
export const ALARM_LEVEL_TYPE = {
  ALARM_MAIN: 'ALARM_MAIN', //主要
  ALARM_MINOR: 'ALARM_MINOR', //次要
  ALARM_URGENCY: 'ALARM_URGENCY', //紧急
  ALARM_WARN: 'ALARM_WARN', //警告
  ALARM_CLEAN: 'ALARM_CLEAN', //清除
}

// 告警分类
export const ALARM_TYPE = {
  MSG_TITLE_SENSOR_ALARM: 'msgTitleSensorAlarm', //传感器告警
  MSG_TITLE_SENSOR_INCREALARM: 'msgTitleSensorIncreAlarm', //传感器增量告警
  MSGT_ITLE_AOI_ALARM: 'msgTitleAoiAlarm', //电子围栏告警
  DEVICE_ALARM: 'deviceAlarm', //设备告警
  EVENT_ALARM: 'eventAlarm', //事件告警
}

// 属性展示方式
export const DEVICE_VAR_TYPE = {
  DEFAULT: 'DEFAULT', //默认
  WATER: 'WATER', // 水流
}

// 文件类型
export const FILES_TYPE = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  OTHER: 'OTHER',
}

// 报表配置数据类型
export const REPORT_DATA_TYPE = {
  ORIGIN: 'ORIGIN', //原始数据
  AGGREGATION: 'AGGREGATION', //聚合数据
  OPERATION: 'OPERATION', //运营数据
}

// 报表类型
export const BOARD_TYPE = {
  TEXT: 'TEXT', //文本组件
  INSTRUMENT: 'INSTRUMENT', //仪表组件
  VIDEO: 'VIDEO', //视频组件
  LOCATION: 'LOCATION', //定位组件
  STATISTICS: 'STATISTICS', //统计组件
}

// 报表视频类型
export const BOARD_VIDEO_TYPE = {
  STREAMING: 'STREAMING', //媒体流
}

// 角色状态
export const STATUS_TYPE = {
  ROLE_START: 'ROLE_START', //启用
  ROLE_STOP: 'ROLE_STOP', //未启用
}

// 数据导出类型
export const DATA_EXPORT_TYPE = {
  RECEIVE: 'RECEIVE', //平台原始报文导出
  RECEIVE_DAY: 'RECEIVE_DAY', //平台接收条数汇总/天
  SEND: 'SEND', //终端发送原始日志导出
  SEND_DAY: 'SEND_DAY', //终端发送条数汇总/天
  SATELLITE: 'SATELLITE', //卫星载荷计数导出
  SATELLITE_DAY: 'SATELLITE_DAY', //卫星载荷计数汇总/天
  FINAL: 'FINAL', //最终汇总数据导出
}

//平台类型
export const PLATFORM_TYPE = {
  OPERATION: 'OPERATION', //运营平台
  DATA_CENTER: 'DATA_CENTER', //数据中心
}

// 设备接入方式
export const CONNECT_TYPE = {
  '4G': '4G',
  LEO: 'LEO',
  GEO: 'GEO',
  LORA: 'Lora',
}

export const MAP_ICON = {
  DEFAULT: 'DEFAULT', //车
  CAR: 'CAR', //车
  SHIP: 'SHIP', //渔船
  COW: 'COW', //奶牛
  FIRE: 'FIRE', //火
  ANIMAL: 'ANIMAL', //动物
  PM25: 'PM25', //pm2.5
  PERSON: 'PERSON', //人流量
  SLOPE: 'SLOPE', //滑坡
}

// 文件内容类型
export const FILE_CONTENT_TYPE = {
  RARE_ANIMAL: 'RARE_ANIMAL', //珍稀动物
  FIRE: 'FIRE', //火灾
  OTHER: 'OTHER', //其他
}

// 文件查询类型
export const FILE_TYPE = {
  TEXT: 'TEXT', //文本
  IMAGE: 'IMAGE', //图片
  VIDEO: 'VIDEO', //视频
  AUDIO: 'AUDIO', //音频
  OTHER: 'OTHER', //其他
}

// 报表配置执行周期
export const REPORT_EXECUTE_FREQUENCY_TYPE = {
  DAY: 'DAY', //每天
  WEEK: 'WEEK', //每周
  MONTH: 'MONTH', //每月
}

// 报表配置最近时间
export const REPORT_TIME_TYPE = {
  LAST_ONE_DAY: 'LAST_ONE_DAY', //最近一天
  LAST_ONE_WEEK: 'LAST_ONE_WEEK', //最近一周
  LAST_ONE_MONTH: 'LAST_ONE_MONTH', //最近一个月
  LAST_THREE_MONTH: 'LAST_THREE_MONTH', //最近三个月
  LAST_ONE_YEAR: 'LAST_ONE_YEAR', //最近一年
  CUSTOM: 'CUSTOM', // 自定义
}

// 诊断日志 解析类型
export const MSG_TRANSFER_TYPE = {
  JSON: 'JSON',
  STRING: 'STRING',
}

// 设备状态
export const DEVICE_STATUS_CODE = {
  NO_ACTIVATE: 'noactivate',
  ACTIVATED: 'inactivate',
}

// 订单状态
export const ORDER_STATUS_CODE = {
  ORDER_UNPAY: 'ORDER_UNPAY', //付款中
  ORDER_PAY_FINISH: 'ORDER_PAY_FINISH', //付款完成
  ORDER_CANCLE: 'ORDER_CANCLE', //取消
}

// 结算状态
export const SETTLEMENT_STATUS_CODE = {
  UNSETTLED: 'UNSETTLED', //未结算
  SETTLED: 'SETTLED', //已结算
}

export const TENANT_TYPE = {
  COMPANY: 'COMPANY',
  PERSON: 'PERSON',
}

// 用户等级
export const USER_LEVEL_TYPE = {
  USER_LEVEL_VIP: 'USER_LEVEL_VIP', //vip
  USER_LEVEL_ORDINARY: 'USER_LEVEL_ORDINARY', //普通
}
